const LockCard = ({ src, isSelected, onClick }) => {
  return (
    <li
      onClick={onClick} // 부모 컴포넌트에서 전달된 클릭 핸들러
      className={`
        flex justify-center items-center 
        rounded-xl
        cursor-pointer
      `}
    >
      <img
        className={`w-[80px] h-[80px] bg-white/30
        rounded-xl aspect-square
        ${
          isSelected ? "border-2 border-blue-500" : "border-2 border-transparent"
        } 
        `}
        src={src}
        alt="Lock"
      />
    </li>
  );
};

export default LockCard;
