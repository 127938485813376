import React, { useState } from "react";
import select from "assets/tab-icons/select-place.png";
import create from "assets/tab-icons/create-lock.png";
import mypage from "assets/tab-icons/my-lock.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export function LoginModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-black rounded-2xl p-6 w-11/12 max-w-sm text-center">
        <button
          className="absolute top-3 right-3 text-white text-lg font-bold"
          onClick={onClose}
        >
          ✕
        </button>
        <p className="text-white my-10 text-lg font-bold">로그인이 필요한 기능입니다.</p>
        <button
          className="bg-indigo-600 text-white rounded-xl px-10 py-2 text-sm font-bold"
          onClick={() => {
            onClose();
            window.location.href = "/login"; // 로그인 페이지로 이동
          }}
        >
          로그인
        </button>
      </div>
    </div>
  );
}


export default function BottomTab() {
  const navigate = useNavigate();
  const nickname = useSelector((state) => state.user.nickname);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRestrictedAction = (action) => {
    if (!nickname) {
      setIsModalOpen(true); // 모달 표시
    } else {
      action();
    }
  };

  return (
    <>
      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <nav
        className="absolute bottom-0 left-auto flex flex-row justify-between items-center py-4 px-5 bg-black/80"
        style={{
          width: "100vw",
          maxWidth: "440px",
          minWidth: "360px",
        }}
      >
        <div
          className="flex flex-col items-center gap-2 justify-center"
          onClick={() => navigate("/select-place")}
        >
          <img src={select} alt="명소 선택하기" className="w-12" />
          <p className="text-xs text-yellow-400">명소 둘러보기</p>
        </div>
        <div
          className="flex flex-col items-center gap-2 justify-center"
          onClick={() => handleRestrictedAction(() => navigate("/create-lock"))}
        >
          <img src={create} alt="자물쇠 생성" className="w-12" />
          <p className="text-xs text-yellow-400">자물쇠 만들기</p>
        </div>
        <div
          className="flex flex-col items-center gap-2 justify-center"
          onClick={() => handleRestrictedAction(() => navigate("/mypage"))}
        >
          <img src={mypage} alt="내 자물쇠 보기" className="w-12" />
          <p className="text-xs text-yellow-400">내 자물쇠 보기</p>
        </div>
      </nav>
    </>
  );
}
