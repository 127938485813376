import React, { useEffect, useState } from "react";
import LockSlider from "./LockSlider";
import BottomTab from "../../components/BottomTab";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLockList } from "apis/lockApi";
import HomeHeader from "components/HomeHeader";
import { setBackgroundId } from "stores/backgroundSlice";
import SideBar from "components/SideBar";
import { useDispatch } from "react-redux";

export default function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [fetchPage, setFetchPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [previewData, setPreviewData] = useState(null);

  // 사이드바 열림 여부 상태
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // 쿼리에서 placeId 가져오기
  const placeId = Number(searchParams.get("placeId")) || 1;

  // 초기 렌더 시 바로 dispatch 호출
  dispatch(setBackgroundId(placeId));

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await getLockList(placeId, page);
        console.log(response);
        let locks = response.data.lockList;
        if (locks.length === 0) {
          setHasMore(false);
          return;
        }

        const remainder = locks.length % 6;
        if (remainder !== 0) {
          const placeholders = Array.from(
            { length: 6 - remainder },
            (_, i) => ({
              padLockId: `placeholder-${page}-${i}`,
              padLockImage: null,
              ownerName: "",
              content: "",
            })
          );
          locks = [...locks, ...placeholders];
        }

        setData((prevData) => (prevData ? [...prevData, ...locks] : locks));
      } catch (error) {
        console.error("Error fetching lock list:", error);
      }
    };

    fetchData(fetchPage);
  }, [fetchPage]);

  const handleNavigate = () => {
    navigate(`/padlock?padLockId=${previewData.padLockId}`, {
      state: previewData,
    });
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="overflow-auto scrollbar-hide">
      <HomeHeader onClick={handleOpenMenu} />
      {previewData ? (
        <article className="flex flex-col w-[350px] mx-auto items-center p-4 bg-black/60 rounded-xl my-4">
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">
              {previewData.ownerName} 님의 자물쇠
            </p>
            <img
              src={previewData.imgPath}
              alt="미리보기 이미지"
              className="w-36 object-contain"
            />
            <p className="text-center text-sm font-bold mb-3">
              {previewData.content}
            </p>
            <div className="grid grid-cols-4 gap-2 w-full">
              {previewData.messagePreviews.length > 0 ? (
                previewData.messagePreviews.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center text-center mb-1"
                  >
                    <img
                      src={require(`assets/locks/light/${item.messageImage}`)}
                      alt={item.ownerName}
                      className="w-16"
                    />
                    <p className="text-[11px] font-bold">
                      {item.ownerName.length > 5
                        ? `${item.ownerName.substring(0, 5)}...`
                        : item.ownerName}
                    </p>
                  </div>
                ))
              ) : (
                <p className="col-span-4 text-center text-gray-100 text-md font-semibold my-6">
                  서브 자물쇠가 없습니다.
                </p>
              )}
            </div>
          </div>

          <img
            src={require("assets/detail-button.png")}
            alt=""
            onClick={handleNavigate}
            className="mt-2"
          />
          {/* <SubmitButton onClick={handleNavigate}>이 자물쇠 전체보기</SubmitButton> */}
        </article>
      ) : (
        // previewData가 없을 때 공간 확보용 영역
        <div className="h-[40vh] bg-black/0"></div>
      )}

      <section className="mt-4 mb-24">
        <LockSlider
          data={data}
          fetchMoreData={() => setFetchPage((prev) => prev + 1)}
          hasMore={hasMore}
          setPreviewData={setPreviewData}
        />
      </section>
      <BottomTab />
      {/* 별도 컴포넌트로 분리한 사이드바 */}
      <SideBar isOpen={isMenuOpen} onClose={handleCloseMenu} />
    </div>
  );
}
