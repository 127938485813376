import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

export default function SelectHeader() {
  const placeName = useSelector((state) => state.background.backgroundName)

  return(
    <header className='w-full'>
      <div className='flex flex-col mt-5 items-center'>
        <h1 className="font-bold text-xl mb-5">명소 둘러보기</h1>
        <div className='flex flex-row gap-2 text-lg items-center font-bold'>
          <FaMapMarkerAlt style={{ color: 'white', fontSize: '20px' }} />
          <span>{placeName}</span>
        </div>
      </div>
    </header>
  )
}