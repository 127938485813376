import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import kakao from "../../assets/kakaologo.png";
import google from "../../assets/web_neutral_rd_na@4x.png";
import naver from "../../assets/naver2.png";
import LomHeader from "components/LomHeader";
import { Button, ButtonContainer, SignUpText } from "./LoginMainPage.styles";
import { getUserInfo } from "apis/userApi";

export default function LoginMainPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("LoginPage")
    // 이미 로그인된 유저인지 판별
    const isLogined = async () => {
      const response = await getUserInfo();
      // console.log(response)
      if (response.status === 200) {
        navigate("/home?placeId=1");
      }
    };

    isLogined();
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between h-[100vh]">
        <LomHeader />
        <ButtonContainer>
          <Button
            $bgcolor="#03C75A"
            onClick={() =>
              (window.location.href =
                "https://api.mydevlogme.site/oauth2/authorization/naver")
            }
          >
            <img
              src={naver}
              alt="네이버 로그인 버튼"
              style={{ width: "25px", marginRight: "10px", marginLeft: "0px" }}
            />
            <span
              style={{
                padding: "0 18px",
                textAlign: "center",
                marginRight: "1px",
              }}
            >
              네이버 로그인
            </span>
          </Button>

          <Button
            $bgcolor="#FEE500"
            onClick={() =>
              (window.location.href =
                "https://api.mydevlogme.site/oauth2/authorization/kakao")
            }
          >
            <img
              src={kakao}
              alt="카카오 로그인 버튼"
              style={{ width: "25px", marginRight: "10px", marginLeft: "0px" }}
            />
            <span
              style={{
                padding: "0 18px",
                textAlign: "center",
                marginRight: "1px",
              }}
            >
              카카오 로그인
            </span>
          </Button>
          <Button
            $bgcolor="#F2F2F2"
            $textcolor="#000"
            onClick={() =>
              (window.location.href =
                "https://api.mydevlogme.site/oauth2/authorization/google")
            }
          >
            <div className="w-full flex flex-row items-center justify-between">
              <img
                src={google}
                alt="구글 로그인 버튼"
                style={{
                  width: "40px",
                  marginRight: "10px",
                  marginLeft: "5px",
                }}
              />
              <span
                style={{
                  padding: "0 18px",
                  textAlign: "center",
                  marginRight: "23px",
                }}
              >
                구글 로그인
              </span>
            </div>
          </Button>
          <Button
            $bgcolor="rgba(255,255,255,0.8)"
            $textcolor="#000"
            onClick={() => navigate("/select-place")}
          >
            {/* 아이콘 없이 동일한 레이아웃 유지 */}
            <span style={{ flex: 1, textAlign: "center", padding: 6 }}>
              비회원으로 둘러보기
            </span>
          </Button>
          <section className="flex flex-row gap-3 justify-center mt-4">
            <SignUpText onClick={() => navigate("/site-sign-in")}>
              로그인
            </SignUpText>
            <SignUpText onClick={() => navigate("/site-sign-up")}>
              회원가입
            </SignUpText>
          </section>
        </ButtonContainer>
      </div>
    </>
  );
}
