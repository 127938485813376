import { getUserInfo } from "apis/userApi"
import LomHeader from "components/LomHeader"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PuffLoader } from "react-spinners"
import { setUserInfo } from "stores/userSlice"

export default function LoadingPage () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserInfo()
        const { userId, nickname } = response.data;
        dispatch(setUserInfo({ userId, nickname }))
        setTimeout(() => {
          setLoading(false)
          navigate("/home?placeId=1")
        }, 2000)
      } catch (error) {
        console.error(error)
        setTimeout(() => {
          navigate("/login")
        }, 2000)
      }
    }

    fetchData();
  },[])

  return (
    <>
    <LomHeader />
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}>
      {loading && (
        <div className="flex flex-col items-center gap-5">
          <p>화면을 구성하는 중입니다.</p>
          <PuffLoader color="#36d7b7" size={60} />
        </div>
      )}
    </div>
    </>
  )
}