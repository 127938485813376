import LomHeader from "components/LomHeader";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import SignUpForm from "./SignUpForm";

const SignUpPage = () => {
  return (
     <>
       <>
        <LomHeader title={"회원가입"} />
        <SignUpForm />
       </>
     </>
  );
};

export default SignUpPage;
