const TeamIntroductionPage = () => {
  return (
    <main className="w-full h-full z-10 bg-[#0D3C6A] text-white">
      <header>Team LOM</header>
      <article>LOM 은 개발자 취업준비생들과 대학교 재학중인 디자이너로 이루어진 프로젝트 팀입니다!</article>
      <footer>Contact Us : example@example.com</footer>
    </main>
  );
};

export default TeamIntroductionPage;
