import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { mainLockPreview } from "apis/lockApi";
import line1 from "assets/line1.png";
import line2 from "assets/line2.png";

const LockSlider = ({ data, fetchMoreData, hasMore, setPreviewData }) => {
  const itemWidth = 120; // 각 아이템의 폭
  const itemHeight = 115; // 각 아이템의 높이
  const itemSpacing = 20; // 아이템 간 간격
  const itemsPerRow = 3; // 한 줄에 표시할 아이템 수
  const itemsPerPage = 6; // 한 페이지에 표시할 아이템 수
  const containerWidth = () => {
    const width = window.innerWidth;
    if (width > 440) {
      return 440;
    } else if (width < 360) {
      return 360;
    } else {
      return width;
    }
  };

  const sliderRef = useRef(null);
  const [{ x }, api] = useSpring(() => ({
    x: 0,
    config: { tension: 9999, friction: 1000 }, // tension(속도), friction(감쇠)
  }));

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(8); // 총 페이지 수

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.length / itemsPerPage));
    }
  }, [data]);

  const firstRowItems = (data || []).filter(
    (_, index) => Math.floor(index / itemsPerRow) % 2 === 0
  );
  const secondRowItems = (data || []).filter(
    (_, index) => Math.floor(index / itemsPerRow) % 2 === 1
  );

  const maxTranslate = 0; // 왼쪽 끝 (첫 페이지)
  const minTranslate = -containerWidth() * (totalPages - 1); // 오른쪽 끝 (마지막 페이지)

  const bind = useDrag(
    ({ down, movement: [mx], cancel }) => {
      const nextX = x.get() + mx * 0.3;

      // 드래그가 끝났을 때 로직
      if (!down) {
        const newPage = Math.round(-nextX / containerWidth()) + 1;

        // 마지막 페이지에서 드래그 추가 요청
        if (newPage >= totalPages && hasMore) {
          fetchMoreData();
        }

        // 페이지 계산 후 애니메이션 이동
        setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
        api.start({ x: -((newPage - 1) * containerWidth()) });
        return;
      }

      // 드래그 중 로직
      if (nextX > maxTranslate) {
        api.start({ x: maxTranslate, immediate: true });
        return;
      }
      if (nextX < minTranslate) {
        // 마지막 페이지 이후 추가로 드래그할 경우 API 호출
        if (hasMore) {
          fetchMoreData();
        }

        api.start({ x: minTranslate, immediate: true });
        return;
      }

      // 드래그 이동 반영
      api.start({ x: nextX, immediate: true });
    },
    { axis: "x", filterTaps: true, threshold: 60 }
  );

  const goToPage = (page) => {
    const xOffset = -((page - 1) * containerWidth());
    api.start({ x: xOffset });
    setCurrentPage(page);

    if (page >= totalPages && hasMore) {
      fetchMoreData();
    }
  };

  const getImagePath = (imageName) => {
    try {
      return require(`../../assets/locks/light/${imageName}`);
    } catch {
      return null;
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  const handlePreview = async (padLockId, imgPath) => {
    try {
      const response = await mainLockPreview(padLockId);
      setPreviewData({
        ...response.data,
        imgPath: imgPath,
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        className="slider-container w-[100vw] min-w-[360px] max-w-[440px]"
        style={{
          height: `${itemHeight * 2 + itemSpacing}px`,
          overflow: "hidden",
          touchAction: "pan-y",
          margin: "0 auto",
        }}
        ref={sliderRef}
      >
        <animated.div
          {...bind()}
          style={{
            display: "flex",
            flexDirection: "row",
            x,
          }}
        >
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <div
              className="w-[100vw] min-w-[360px] max-w-[440px]"
              key={`page-${pageIndex}`}
              style={{
                display: "flex",
                flexDirection: "column",
                // width: `${containerWidth}px`,
                flexShrink: 0,
              }}
            >
              <div
                className="relative"
                style={{
                  display: "flex",
                  marginBottom: `${itemSpacing}px`,
                }}
              >
                <img
                  src={line1}
                  alt="line1"
                  className="absolute top-[1.5vh] w-full h-1"
                />
                {firstRowItems
                  .slice(pageIndex * itemsPerRow, (pageIndex + 1) * itemsPerRow)
                  .map((item, index) => (
                    <div
                      key={item.padLockId || `first-${pageIndex}-${index}`}
                      className="flex flex-col items-center justify-center rounded-md ms-4"
                      style={{
                        width: `${itemWidth}px`,
                        height: `${itemHeight}px`,
                        marginRight: `${itemSpacing}px`,
                      }}
                    >
                      {item.padLockImage ? (
                        <div
                          onClick={() =>
                            handlePreview(
                              item.padLockId,
                              getImagePath(item.padLockImage)
                            )
                          }
                        >
                          <img
                            src={getImagePath(item.padLockImage)}
                            alt="lock"
                            style={{
                              width: "90px",
                              height: "90px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      ) : (
                        <div style={{ width: "90px", height: "90px" }} />
                      )}
                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        {truncateText(item.content, 8)}
                      </p>
                    </div>
                  ))}
              </div>
              <div className="relative" style={{ display: "flex" }}>
                <img
                  src={line2}
                  alt="line2"
                  className="absolute top-[1.5vh] w-full h-1"
                />
                {secondRowItems
                  .slice(pageIndex * itemsPerRow, (pageIndex + 1) * itemsPerRow)
                  .map((item, index) => (
                    <div
                      key={item.padLockId || `second-${pageIndex}-${index}`}
                      className="flex flex-col items-center justify-center rounded-md ms-4"
                      style={{
                        width: `${itemWidth}px`,
                        height: `${itemHeight}px`,
                        marginRight: `${itemSpacing}px`,
                      }}
                    >
                      {item.padLockImage ? (
                        <div
                          onClick={() =>
                            handlePreview(
                              item.padLockId,
                              getImagePath(item.padLockImage)
                            )
                          }
                        >
                          <img
                            src={getImagePath(item.padLockImage)}
                            alt="lock"
                            style={{
                              width: "90px",
                              height: "90px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      ) : (
                        <div style={{ width: "90px", height: "90px" }} />
                      )}
                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      >
                        {truncateText(item.content, 8)}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </animated.div>
      </div>

      <nav
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <button
          onClick={() => goToPage(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
          style={{ marginRight: "10px" }}
        >
          이전
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => goToPage(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages && !hasMore}
          style={{ marginLeft: "10px" }}
        >
          다음
        </button>
      </nav>
    </>
  );
};

export default LockSlider;
