export default function PolaroidCard({ imgPath, placeName, onClick }) {

  return(
    <>
      <div className="flex flex-col items-center gap-1" onClick={onClick}>
        <div className="bg-white p-1 pb-3">
          <img
            className="h-10"
            src={imgPath}
            alt={placeName}
          />
        </div>
        <p className="text-yellow-400 text-[8.4px]">{placeName}</p>
      </div>
    </>
  )
}
