import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const BackgroundFilter = ({ children }) => {
  const [isIntroPage, setIsIntroPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    setIsIntroPage(location.pathname === "/" || location.pathname === "/team");
  }, [location.pathname, isIntroPage]);
  return (
    <>
      {isIntroPage ? (
        <>{children}</>
      ) : (
        <div
          className="flex flex-col items-center py-5 scrollbar-hide relative"
          style={{
            height: "100dvh",
            minHeight: "800px",
            maxHeight: "1000px",
            background: `linear-gradient(
          180deg,
          rgba(22, 5, 57, 0.9) 0%,
          rgba(59, 13, 71, 0.47) 27%,
          rgba(0, 38, 83, 0.6) 65%,
          rgba(23, 67, 110, 0.82) 100%
        )`,
            position: "relative",
            overflow: "auto",
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default BackgroundFilter;
