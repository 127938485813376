import { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { createSubLock } from "apis/lockApi";
import { MdOutlineLock } from "react-icons/md";
import LockCard from "./LockCard";
import locks from "assets/locks/locks.json";
import { useToast } from "components/ToastContext";

const CreateMessagekPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const showToast = useToast();

  const [step, setStep] = useState(0);
  const [lockData, setLockData] = useState({});

  const [selectedCardId, setSelectedCardId] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("basic");

  const [lockmessage, setLockMessage] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const maxLength = 200;

  // 카테고리와 자물쇠 매핑
  const categories = [
    { name: "기본", id: "basic" },
    { name: "하트", id: "heart" },
    { name: "편지", id: "letter" },
    { name: "선물", id: "gift" },
    { name: "복주머니", id: "bag" },
    { name: "눈사람", id: "snowman" },
  ];

  const lockContainerRef = useRef({});

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedCardId(locks[categoryId][0].id);
  };

  const handleClickNext = () => {
    setStep(step + 1);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;

    // 한글 글자 수 계산
    const length = input.length;

    // 200자를 초과하면 기존 값 유지
    if (length <= maxLength) {
      setLockMessage(input);
    }
  };

  const handleButtonClick = async () => {
    const response = await createSubLock({
      lockId: lockData.padLockId,
      messageImageId: selectedCardId,
      content: lockmessage,
      visible: !isPrivate,
    });
    if (response.status === 201) {
      showToast("메시지 생성 성공", true);
      navigate(`/padlock?padLockId=${lockData.padLockId}`,{replace: true});
    } else {
      console.log(response)
      showToast(
        "메시지 생성에 실패하였습니다. 잠시 후 다시 시도해주세요.",
        false
      );
    }
  };

  useEffect(() => {
    setLockData(location.state.data);
  }, [lockData]);

  return (
    <>
      <header className="relative w-full max-w-[400px] flex items-center mt-[20px] mb-4">
        <IoIosArrowBack
          className="absolute left-[20px] text-3xl cursor-pointer"
          onClick={() => {
            if (step === 1) {
              setStep(step - 1);
            } else {
              navigate(-1);
            }
          }}
        />
        <h1 className="w-full text-center font-bold text-[25px]">
          메시지 남기기
        </h1>
      </header>
      <form
        className="flex flex-col justify-center items-center gap-5
        w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        {step === 0 && (
          <div className="flex flex-row items-center">
            <MdOutlineLock className="w-[24px] h-[24px]" />
            <p className="ml-[5px] font-semibold text-[17px]">
              자물쇠 선택하기
            </p>
          </div>
        )}

        {selectedCardId ? (
          <img
            className="h-[150px]"
            src={require("assets/locks" +
              locks[selectedCategory][(selectedCardId - 1) % 6].src)}
            alt=""
          />
        ) : (
          <img
            className="h-[150px]"
            src={require("assets/locks" + locks[selectedCategory][0].src)}
            alt=""
          />
        )}
        {step === 0 && (
          <>
            {/* 자물쇠 목록 */}
            <section
              className="flex flex-col
                              w-[320px]
                              px-4 py-1 rounded-lg
                              bg-black/80 "
            >
              <ul className="flex overflow-x-auto whitespace-nowrap scrollbar-hide gap-2">
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className={`px-3 py-1 font-semibold rounded-md text-sm
                     ${
                       category.id === selectedCategory
                         ? "text-white bg-[#433DE7]"
                         : "bg-white/70 text-black"
                     }`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
              <section className="flex flex-col mt-4 overflow-y-auto h-[190px]">
                {Object.entries(locks).map(([categoryId, lockList]) => {
                  if (categoryId == selectedCategory) {
                    return (
                      <section
                        key={categoryId}
                        id={`${categoryId}`}
                        ref={(el) =>
                          (lockContainerRef.current[categoryId] = el)
                        }
                        className="mb-4"
                      >
                        <ul className="grid grid-cols-3 gap-3">
                          {lockList.map((lock) => (
                            <LockCard
                              key={lock.id}
                              src={require("assets/locks" + lock.src)}
                              isSelected={selectedCardId === lock.id}
                              onClick={() => handleCardClick(lock.id)}
                            />
                          ))}
                        </ul>
                      </section>
                    );
                  }
                })}
              </section>
            </section>
            <button
              className={
                "bg-black/80 text-white font-bold py-2 h-[50px] px-20 rounded-xl"
              }
              onClick={() => {
                handleClickNext();
              }}
            >
              다음
            </button>
          </>
        )}
        {step === 1 && (
          <>
            {/* 메시지 작성 */}
            <p className="w-[320px] text-center">{lockData.content}</p>
            <section className="relative">
              <textarea
                value={lockmessage}
                onChange={handleInputChange}
                className="text-white bg-black/80
            w-[320px] h-[190px] rounded-xl p-4
            resize-none text-sm"
                placeholder={
                  "자물쇠에 담을 문구를 작성해 주세요.\n\n부적절한 메시지는\n공개, 비공개 여부와 상관없이 \n경찰 수사 요청 시 제공될 수 있습니다."
                }
              />
              <p
                className={`absolute right-3 bottom-3 text-sm ${
                  lockmessage.length === maxLength
                    ? "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {lockmessage.length}/{maxLength}자
              </p>
            </section>
            <div className="">
              <input
                type="checkbox"
                onChange={(e) => {
                  setIsPrivate(e.target.checked);
                }}
              />
              <label className="ml-[6px] text-xs">
                자물쇠 주인에게만 보이기(비공개)
              </label>
            </div>
            <button
              className={
                "bg-black/80 text-white font-bold h-[45px] w-[250px] rounded-xl"
              }
              onClick={handleButtonClick}
            >
              생성하기
            </button>
          </>
        )}
      </form>
    </>
  );
};

export default CreateMessagekPage;
