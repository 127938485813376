import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaBars, FaMapMarkerAlt, FaEye, FaEnvelope, FaEyeSlash, FaEllipsisV, FaCalendarAlt, FaArrowRight } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineCalendar } from "react-icons/ai";
import { getMyMainLock, getMySubLock } from "apis/lockApi";
import SideBar from "components/SideBar";

const MyPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("lock");
  const [mainLockData, setMainLockData] = useState(null);
  const [subLockData, setSubLockData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLock, setSelectedLock] = useState(null);

  const handleTabClick = (tab) => setActiveTab(tab);

  useEffect(() => {
    const fetchMainData = async () => {
      try {
        const response = await getMyMainLock();
        setMainLockData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSubData = async () => {
      try {
        const response = await getMySubLock();
        setSubLockData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMainData();
    fetchSubData();
  }, []);

  const lockData = mainLockData
    ? mainLockData.map((lock, index) => ({
        id: lock.padLockId || `lock-${index}`,
        image: lock.padLockImage,
        title: lock.content || "내용 없음",
        date: lock.createDate || "알 수 없는 날짜",
        views: lock.views || 0,
        messageCount: lock.messageCount || 0,
        placeId: lock.placeId,
      }))
    : [];

  const messageData = subLockData
    ? subLockData.map((sub, index) => ({
        id: sub.lockId || `message-${index}`,
        subLockId: sub.messageId || `sub-${index}`,
        messageImage: sub.messageImage,
        padLockImage: sub.padLockImage,
        sender: sub.messageOwner || "익명",
        receiver: sub.padLockOwner || "익명",
        messageContent: sub.messageContent || "메시지 없음",
        padLockContent: sub.padLockContent || "메시지 없음",
        date: sub.messageCreateDateTime || "알 수 없는 날짜",
        visible: sub.visible,
      }))
    : [];

  const handleLockClick = (lock) => {
    setSelectedLock(lock);
  };

  const closeModal = () => {
    setSelectedLock(null);
  };

  const placeName = [
    { id: 1, name: "남산 타워" },
    { id: 2, name: "경주 하서항" },
    { id: 3, name: "명동 성당" },
    { id: 4, name: "포항 호미곶" },
    { id: 5, name: "별마당 도서관" },
    { id: 6, name: "성산 일출봉" },
  ];

  return (
    <>
      <div className="w-[85%]">
        <div className="relative w-full flex items-center justify-between px-4 mt-10 mb-12">
          <IoIosArrowBack
            className="text-white text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="text-center text-white text-xl font-bold">
            나의 자물쇠 보기
          </h1>
          <FaBars style={{ fontSize: "20px" }} onClick={() => setIsMenuOpen(true)} />
        </div>

        <div className="w-full rounded-lg shadow-lg mt-4 py-2">
          <div className="flex">
            <button
              className={`flex-1 py-3 rounded-t-md text-center font-bold ${
                activeTab === "lock" ? "bg-[#433DE7]" : "bg-[#FFFFFF4D]"
              }`}
              onClick={() => handleTabClick("lock")}
            >
              추억 자물쇠
            </button>
            <button
              className={`flex-1 py-3 text-center rounded-t-md font-bold ${
                activeTab === "message" ? "bg-[#433DE7]" : "bg-[#FFFFFF4D]"
              }`}
              onClick={() => handleTabClick("message")}
            >
              메시지
            </button>
          </div>

          <div
            className={`p-4 h-[70vh] overflow-y-auto ${
              activeTab === "lock" ? "grid grid-cols-3 gap-4" : "flex flex-col"
            } bg-[rgba(217,217,217,0.3)] w-full rounded-b-lg`}
          >
            {activeTab === "lock"
              ? lockData.map((item) => (
                  <div
                    key={item.id}
                    className="flex flex-col items-center rounded-lg shadow-sm relative"
                    onClick={() => handleLockClick(item)}
                  >
                    <img
                      src={require(`assets/locks/light/${item.image}`)}
                      alt="lock"
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                    <div className="text-center">
                      <h2 className="font-bold text-sm truncate w-24 overflow-hidden">
                        {item.title}
                      </h2>
                      <p className="text-gray-300 text-xs">{item.date}</p>
                    </div>
                  </div>
                ))
              : messageData.map((message, index) => (
                  <div
                    key={`${message.id}-${message.subLockId}`}
                    className={`relative flex items-center px-4 py-4 rounded-lg w-full mb-5 mx-auto ${
                      index % 2 === 0 ? "bg-white text-black" : "bg-[#433DE7] text-white"
                    }`}
                  >
                    <div
                      className="absolute bottom-[-10px] right-4 w-0 h-0"
                      style={{
                        borderLeft: "10px solid transparent",
                        borderRight: "10px solid transparent",
                        borderTop: `10px solid ${
                          index % 2 === 0 ? "#ffffff" : "#433DE7"
                        }`,
                      }}
                    ></div>
                    <div className="flex flex-col justify-center items-center">
                      <div className="relative">
                        <img
                          src={require(`assets/locks/light/${message.padLockImage}`)}
                          alt="자물쇠 이미지"
                          className="w-24"
                          style={{
                            transform: "rotate(5deg)",
                            zIndex: 1,
                          }}
                        />
                        <img
                          src={require(`assets/locks/light/${message.messageImage}`)}
                          alt="메시지 이미지"
                          className="w-12 absolute top-2 left-7"
                          style={{
                            transform: "rotate(-20deg)",
                            zIndex: 2,
                          }}
                        />
                      </div>
                      <p className="text-[12px] truncate font-bold">{message.padLockContent}</p>
                    </div>

                    <div className="ms-4 w-full">
                      <p className="text-[13px] mb-2">{message.receiver} 에게</p>
                      <p className="text-md font-bold mb-2">
                        {message.messageContent || "메시지 내용 없음"}
                      </p>
                      <div className="flex flex-row items-center gap-3 mt-4 text-[11px] mb-2">
                        <div className="flex items-center gap-1">
                          <FaCalendarAlt />
                          <p>{message.date}</p>
                        </div>
                        <div className="flex items-center gap-2">
                          {message.visible ? (
                            <FaEye className="text-green-500" />
                          ) : (
                            <FaEyeSlash className="text-red-500" />
                          )}
                          <p>{message.visible ? "공개" : "비공개"}</p>
                        </div>
                      </div>
                      <p
                        className="text-[11px] flex flex-row items-center gap-1 justify-end"
                        onClick={() => navigate(`/padlock?padLockId=${message.id}`)}
                      >
                        <FaArrowRight />
                        이 자물쇠 보러가기
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>

      {selectedLock && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white shadow-lg p-4 w-4/5">
            <div className="flex flex-col">
              <div className="relative bg-black">
                <img
                  src={require(`assets/attractions/attraction-${selectedLock.placeId}.png`)}
                  alt="place"
                  className="object-cover mb-4 w-full h-full opacity-70"
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="flex flex-row gap-2 justify-center items-center font-bold text-md mb-2 -mt-2">
                    <FaMapMarkerAlt />
                    <p>
                      {placeName.find((place) => place.id === selectedLock.placeId)?.name ||
                        "알 수 없는 장소"}
                    </p>
                  </div>
                  <img
                    src={require(`assets/locks/light/${selectedLock.image}`)}
                    alt="lock"
                    className="w-36 h-36"
                  />
                </div>
              </div>
              <p className="text-black left-0 py-2 mx-2 font-bold text-lg">{selectedLock.title}</p>
              <div className="flex flex-row justify-between mx-2 text-sm font-semibold">
                <div className="flex flex-row items-center gap-1 text-gray-600">
                  <AiOutlineCalendar />
                  <p>{selectedLock.date}</p>
                </div>
                <div className="flex flex-row items-center gap-1 text-blue-600">
                  <FaEye />
                  <p>{selectedLock.views}</p>
                </div>
                <div className="flex flex-row items-center gap-1 text-red-600">
                  <FaEnvelope />
                  <p>{selectedLock.messageCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-4 gap-10">
            <button
              className="px-4 py-2 bg-[#433DE7] rounded-lg w-32 font-bold"
              onClick={() => navigate(`/padlock?padLockId=${selectedLock.id}`)}
            >
              자세히 보기
            </button>
            <button
              className="px-4 py-2 text-black bg-gray-200 rounded-lg w-32 font-bold"
              onClick={closeModal}
            >
              닫기
            </button>
          </div>
        </div>
      )}

      <SideBar isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
};

export default MyPage;
