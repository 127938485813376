import { createSlice } from "@reduxjs/toolkit";
import image1 from "assets/attractions/attraction-1.png";
import image2 from "assets/attractions/attraction-2.png";
import image3 from "assets/attractions/attraction-3.png";
import image4 from "assets/attractions/attraction-4.png";
import image5 from "assets/attractions/attraction-5.png";
import image6 from "assets/attractions/attraction-6.png";

const initialState = {
  backgroundImage: image1, // 초기 배경 이미지 설정
  backgroundName: "남산타워",
  backgroundId: 1,
};

const placeInfo = [
  { id: 1, src: image1, name: "남산 타워" },
  { id: 2, src: image2, name: "경주 하서항" },
  { id: 3, src: image3, name: "명동 성당" },
  { id: 4, src: image4, name: "포항 호미곶" },
  { id: 5, src: image5, name: "별마당 도서관" },
  { id: 6, src: image6, name: "성산 일출봉" },
];

export const backgroundSlice = createSlice({
  name: "background",
  initialState,
  reducers: {
    setBackgroundImage: (state, action) => {
      state.backgroundImage = action.payload; // 배경 이미지를 업데이트
    },
    setBackgroundName: (state, action) => {
      state.backgroundName = action.payload; // 배경 이미지를 업데이트
    },
    setBackgroundId: (state, action) => {
      const placeId = action.payload;
      state.backgroundId = placeId;
      state.backgroundImage = placeInfo[placeId - 1].src;
      state.backgroundName = placeInfo[placeId - 1].name;
    },
  },
});

export const { setBackgroundImage, setBackgroundName, setBackgroundId } =
  backgroundSlice.actions;
export default backgroundSlice.reducer;
