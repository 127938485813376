import styled from "styled-components";


export const InputContainer = styled.div`
  margin: 12px 28px; /* mx-5와 동일한 좌우 마진 */
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  font-size: 16px;
  outline: none;
  margin-top: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  color: black;

  ::placeholder {
    color: #464646;
    font-weight: 800; /* 쉼표 제거 */
  }
`;



InputContainer.displayName = "InputContainer";
StyledInput.displayName = "StyledInput";
