import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const BackgroundImage = ({ bgimage, children }) => {
  const [isIntroPage, setIsIntroPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    setIsIntroPage(location.pathname === "/" || location.pathname === "/team");
  }, [location.pathname, isIntroPage]);
  return (
    <>
      {isIntroPage ? (
        <>{children}</>
      ) : (
        <div
          style={{
            width: "100vw",
            maxWidth: "440px",
            minWidth: "360px",
            minHeight: "800px",
            maxHeight: "1000px",
            height: "100dvh",
            backgroundColor: "#0D3C6A" /* 가운데 영역의 배경색 */,
            backgroundImage: `url(${bgimage})`,
            backgroundSize: "cover",
            backgroundPosition: " center",
            color: "white",
            margin: "0 auto",
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default BackgroundImage;
