import React, { useEffect, useState } from "react";
import {
  FaTimes,
  FaPen,
  FaRandom,
  FaFire,
  FaSignOutAlt,
  FaUsers,
  FaUserMinus,
  FaQuestionCircle,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import select from "assets/tab-icons/select-place.png";
import create from "assets/tab-icons/create-lock.png";
import myLock from "assets/tab-icons/my-lock.png";
import {
  cancelAccount,
  getUserInfo,
  signOut,
  updateUserInfo,
} from "apis/userApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserInfo } from "stores/userSlice";

function ConfirmModal({ isOpen, onClose, onConfirm, message }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-700/90 rounded-lg p-6 w-11/12 max-w-sm z-[1100]">
        <p className="text-lg font-bold mb-4">{message}</p>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
          >
            취소
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default function SideBar({ isOpen, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    nickname: "",
    padLockCount: 0,
    messageSentCount: 0,
    messageReceivedCount: 0,
  });
  const [inputNickname, setInputNickname] = useState("");
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false); // 로그아웃 모달 상태
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // 회원탈퇴 모달 상태
  const [isAnimating, setIsAnimating] = useState(false); // 애니메이션 상태
  const [isFullyVisible, setIsFullyVisible] = useState(false); // DOM 렌더링 상태

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserInfo();
      if (response.status === 200) {
        const data = response.data;
        setUserInfo({
          nickname: data.nickname,
          padLockCount: data.padLockCount,
          messageSentCount: data.messageSentCount,
          messageReceivedCount: data.messageReceivedCount,
        });
        setInputNickname(data.nickname);
      }
    };

    fetchData();

    if (isOpen) {
      setIsFullyVisible(true); // DOM을 렌더링
      setTimeout(() => setIsAnimating(true), 10); // 약간의 지연 후 애니메이션 시작
    } else {
      setIsAnimating(false); // 애니메이션 종료
      const timer = setTimeout(() => setIsFullyVisible(false), 300); // 애니메이션 후 DOM 제거
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isFullyVisible) return null; // DOM 제거

  const handleSaveNickname = () => {
    if (inputNickname.length < 2) {
      alert("닉네임은 최소 2글자 이상이어야 합니다.");
      return;
    }

    setUserInfo((prev) => ({
      ...prev,
      nickname: inputNickname,
    }));

    try {
      updateUserInfo({ nickname: inputNickname });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      dispatch(clearUserInfo());
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await cancelAccount();
      dispatch(clearUserInfo());
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  const { nickname, padLockCount, messageSentCount, messageReceivedCount } =
    userInfo;

  return (
    <>
      <ConfirmModal
        isOpen={isLogoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        onConfirm={handleLogout}
        message="정말 로그아웃 하시겠습니까?"
      />
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteAccount}
        message="정말 회원탈퇴 하시겠습니까?"
      />
      {/* 사이드바 외부 클릭시 닫기 */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 z-40"
          onClick={onClose}
        />
      )}
      <div
        className={`fixed top-0 h-screen z-50 p-4 transition-transform duration-300 overflow-y-auto`}
        style={{
          width: "80%", // 중앙 요소의 80% 너비
          maxWidth: "352px", // 440px의 80%
          minWidth: "288px", // 360px의 80%
          backgroundColor: "#000000",
          opacity: "90%",
          position: "absolute",
          right: "0", // 중앙 요소 기준 오른쪽 위치 (440px / 2)
          transform: isAnimating ? "translateX(0)" : "translateX(100%)", // 오른쪽에서 슬라이드
          // transform: "translateX(0)",
          transition: "transform 0.3s ease-in-out", // 애니메이션 효과
        }}
      >
        <button onClick={onClose} className="mb-6">
          <IoClose className="text-white text-2xl" />
        </button>

        {userInfo.nickname ? (
          <>
            <div className="mb-6">
              <p className="text-lg font-bold">
                <span style={{ color: "#F0A550" }}>{userInfo.nickname}</span> 님
                안녕하세요!
              </p>
            </div>
            <div className="mb-4">
              <div className="flex flex-row justify-between items-center text-sm text-gray-300 mb-4">
                <span className="flex items-center gap-2 font-bold text-lg">
                  <FaRandom style={{ color: "#433DE7" }} />
                  닉네임 변경
                </span>
                {/* <span className="underline">랜덤 생성하기</span> */}
              </div>
              <div className="flex flex-nowrap items-center gap-2 bg-white rounded-md px-3 py-2">
                <FaPen className="text-gray-700" />
                <input
                  type="text"
                  className="bg-transparent flex-1 text-black focus:outline-none"
                  placeholder="새 닉네임 입력"
                  value={inputNickname}
                  onChange={(e) => setInputNickname(e.target.value)}
                  minLength={2}
                  maxLength={8}
                />
                <button
                  className="bg-indigo-600 text-white rounded px-2 py-1 text-sm whitespace-nowrap"
                  onClick={handleSaveNickname}
                >
                  저장
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-6 mb-6 ms-3 font-bold">
              <div
                className="flex flex-row items-center gap-3"
                onClick={() => navigate("/create-lock")}
              >
                <img className="w-8 h-8" src={create} alt="자물쇠 만들기" />
                <p className="text-base">자물쇠 만들기</p>
              </div>
              <div
                className="flex flex-row items-center gap-3"
                onClick={() => navigate("/select-place")}
              >
                <img className="w-8 h-8" src={select} alt="명소 둘러보기" />
                <p className="text-base">명소 둘러보기</p>
              </div>
              <div
                className="flex flex-row items-center gap-3"
                onClick={() => navigate("/mypage")}
              >
                <img className="w-8 h-8" src={myLock} alt="내 자물쇠 보기" />
                <p className="text-base">내 자물쇠 보기</p>
              </div>
            </div>
            <hr className="border-t-2 border-gray-300 my-6" />

            {/* 내 활동 섹션 */}
            <div className="mb-6">
              <div className="flex flex-row items-center gap-2 mb-4 text-lg font-bold">
                <FaFire className="text-red-500" />
                <p>내 활동</p>
              </div>
              <div className="flex flex-col gap-1 ms-3 text-md font-bold">
                <p>만든 자물쇠 : {padLockCount}</p>
                <p>남긴 메시지 : {messageSentCount}</p>
                <p>받은 메시지 : {messageReceivedCount}</p>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center items-center h-[600px]">
            <button
              onClick={() => navigate("/login")}
              className="bg-[#433DE7] w-[215px] h-[44px] text-sm text-white rounded-lg"
            >
              로그인 페이지로 이동
            </button>
          </div>
        )}
        <hr className="border-t-2 border-gray-300 my-6" />

        <div className="flex flex-col gap-4 ms-5 font-bold">
          <div className="flex flex-row items-center gap-10">
            {userInfo.nickname && (
              <div
                className="flex items-center gap-2"
                onClick={() => setLogoutModalOpen(true)}
              >
                <FaSignOutAlt />
                <span>로그아웃</span>
              </div>
            )}
            <div className="flex items-center gap-2"
                onClick={() => navigate('/team')}>
              <FaUsers />
              <span>팀 소개</span>
            </div>
          </div>
          <div className="flex flex-row items-center gap-10">
            {userInfo.nickname && (
              <div
                className="flex items-center gap-2 text-red-500"
                onClick={() => setDeleteModalOpen(true)}
              >
                <FaUserMinus />
                <span>회원탈퇴</span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <FaQuestionCircle />
              <span>문의하기</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
