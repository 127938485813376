import axios from "axios";

const DOMAIN = process.env.REACT_APP_DOMAIN;

export default function API() {
  const instance = axios.create({
    baseURL: DOMAIN,
    withCredentials: true,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true; // 무한 루프 방지 플래그

        try {
          // Refresh 요청
          const res = await axios.post(
            `${DOMAIN}/reissue`,
            {},
            { withCredentials: true }
          );
          // 새로운 Access Token 저장
          const newAccessToken = res.headers.authorization; // 헤더에서 토큰 가져오기
          axios.defaults.headers.common["Authorization"] = newAccessToken;

          // 원래 요청에 새 Access Token 설정 후 재요청
          originalRequest.headers["Authorization"] = newAccessToken;
          return instance(originalRequest);
        } catch (refreshError) {
          // Refresh Token 만료 또는 오류 발생 시
          return Promise.reject(refreshError);
        }
      }

      // 다른 에러는 그대로 반환
      return Promise.reject(error);
    }
  );
  return instance;
}
