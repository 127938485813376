import LomHeader from "components/LomHeader";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import SignInForm from "./SignInForm";

const SignInPage = () => {
  return (
     <>
       <>
        <LomHeader />
        <SignInForm />
       </>
     </>
  );
};

export default SignInPage;
