import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  placeId: 1,
  design: 1,
};

export const mainLockSlice = createSlice({
  name: "mainLock",
  initialState,
  reducers: {
    setPlaceId: (state, action) => {
      state.placeId = action.payload;
    },
    setdesign: (state, action) => {
      state.design = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { setPlaceId, setdesign, setMessage } = mainLockSlice.actions;
export default mainLockSlice.reducer;
