import { FaMapMarkerAlt, FaChevronDown, FaBars } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function HomeHeader({ onClick }) {
  const placeName = useSelector((state) => state.background.backgroundName);
  const nickname = useSelector((state) => state.user.nickname);
  const navigate = useNavigate();

  return (
    <header className="w-full">
      <div className="flex flex-row items-center gap-2 mt-4 relative justify-center">
        <FaMapMarkerAlt style={{ color: 'white', fontSize: '20px' }} />
        <span className="" style={{ fontSize: '20px' }}>{placeName}</span>
        <FaChevronDown
          style={{ fontSize: '20px', color: 'white' }}
          onClick={() => navigate("/select-place")}
        />
        {nickname && (
          <FaBars
            style={{ fontSize: '20px', color: 'white' }}
            className="absolute right-10"
            onClick={() => onClick()}
          />
        )}
      </div>
    </header>
  );
}
