import React from "react";
import Router from "./Router";
import { useSelector } from "react-redux";
import { ToastProvider } from "components/ToastContext";

function App() {
  return (
    <>
      <ToastProvider>
        <Router />
      </ToastProvider>
    </>
  );
}

export default App;
