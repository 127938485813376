import API from "../API"

// 메인 자물쇠
export const createLock = async (data) => {
    return await API().post("/padlock", data)
    .then((response) => response)
    .catch((error) => error)
}

export const getLockList = async (placeId, pageNumber) => {
    return await API().get(`/padlock/list?placeId=${placeId}&pageNumber=${pageNumber}`)
    .then((response) => response)
    .catch((error) => error)
}

export const getLockDetail = async (padLockId) => {
    return await API().get(`/padlock/detail?padLockId=${padLockId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const deleteLock = async (padLockId) => {
    return await API().delete(`/padlock?padLockId=${padLockId}`,)
    .then((response) => response)
    .catch((error) => error)
}

export const updateLock = async (data) => {
    return await API().put("/padlock", data)
    .then((response) => response)
    .catch((error) => error)
}

// 서브 자물쇠
export const createSubLock = async (data) => {
    return await API().post("/message", data)
    .then((response) => response)
    .catch((error) => error)
}

export const patchLock = async (data) => {
    return await API().put("/padlock", data)
    .then((response) => response)
    .catch((error) => error)
}
export const updateSubLock = async (data) => {
    return await API().put("/message", data)
    .then((response) => response)
    .catch((error) => error)
}

export const deleteMessage = async (messageId) => {
    return await API().delete(`/message?messageId=${messageId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const getMySubLockList = async () => {
    return await API().get("/message/my-list")
    .then((response) => response)
    .catch((error) => error)
}

export const getSubLockDetail = async (messageId) => {
    return await API().get(`/message/detail?messageId=${messageId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const mainLockPreview = async (padLockId) => {
    return await API().get(`/padlock/preview?padLockId=${padLockId}`)
    .then((response) => response)
    .catch((error) => error)
}

export const getMyMainLock = async () => {
    return await API().get("/padlock/my-list")
    .then((response) => response)
    .catch((error) => error)
}

export const getMySubLock = async () => {
    return await API().get("/message/my-list")
    .then((response) => response)
    .catch((error) => error)
}