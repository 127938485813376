import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginMainPage from "./pages/LoginPage/LoginMainPage";
import MyPage from "./pages/MyPage/MyPage";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import SelectPlacePage from "./pages/MainPage/SelectPlacePage";
import SignInPage from "./pages/SignInPage/SignInPage";
import PadLockDetailPage from "pages/LockDetailPage/PadLockDetailPage";
import IntroductionPage from "pages/IntroductionPage/IntroductionPage";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import CreateLockPage from "pages/CreateLockPage/CreateLockPage";
import HomePage from "pages/MainPage/HomePage";
import CreateMessagekPage from "pages/CreateMessagekPage/CreateMessagekPage";
import ErrorPage from "pages/ErrorPage";
import SharePage from "pages/SharePage/SharePage";
import MessageListPage from "pages/LockDetailPage/MessageListPage";
import BackgroundImage from "components/BackgroundImage";
import BackgroundFilter from "components/BackgroundFilter";
import { useSelector } from "react-redux";
import TeamIntroductionPage from "pages/TeamIntroductionPage/TeamIntroductionPage";

export default function Router() {
  const hasVisited = localStorage.getItem("hasVisited") === "true";
  // console.log(localStorage.getItem("hasVisited"))
  const backgroundImage = useSelector(
    (state) => state.background.backgroundImage
  );
  return (
    <BrowserRouter>
      <BackgroundImage bgimage={backgroundImage}>
        <BackgroundFilter>
          <Routes>
            <Route
              path="/"
              element={
                hasVisited ? <Navigate to="/login" /> : <IntroductionPage />
              }
            />
            <Route path="/login" element={<LoginMainPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/create-lock" element={<CreateLockPage />} />
            <Route path="/create-message" element={<CreateMessagekPage />} />
            <Route path="/mypage" element={<MyPage />} />
            <Route path="/share" element={<SharePage />} />
            <Route path="/padlock" element={<PadLockDetailPage />} />
            <Route path="/message" element={<MessageListPage />} />
            <Route path="/select-place" element={<SelectPlacePage />} />
            <Route path="/loading" element={<LoadingPage />} />
            <Route path="/team" element={<TeamIntroductionPage />} />

            <Route path="/site-sign-up" element={<SignUpPage />} />
            <Route path="/site-sign-in" element={<SignInPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BackgroundFilter>
      </BackgroundImage>
    </BrowserRouter>
  );
}
