import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createLock } from "apis/lockApi";
import { setMessage, setPlaceId } from "stores/mainLockSlice";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import LockCard from "./LockCard";
import locks from "assets/locks/locks.json";
import { setBackgroundId } from "stores/backgroundSlice";
import { useToast } from "components/ToastContext";

const CreateLockPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const placeId = useSelector((state) => state.mainLock.placeId);
  const showToast = useToast();
  const [lockmessage, setLockMessage] = useState("");
  const maxLength = 30;
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("basic");
  console.log(locks[selectedCategory][0].src, selectedCardId);

  useEffect(() => {}, []);
  const handleInputChange = (e) => {
    const input = e.target.value;

    // 한글 글자 수 계산
    const length = input.length;

    // 50자를 초과하면 기존 값 유지
    if (length <= maxLength) {
      setLockMessage(input);
    }
  };
  const handleButtonClick = async () => {
    dispatch(setMessage(lockmessage));
    // console.log(placeId, selectedCardId, lockmessage)
    try {
      const response = await createLock({
        placeId,
        padLockImageId: selectedCardId,
        content: lockmessage,
      });
      console.log(response);
      showToast("자물쇠 생성 성공", true);
      navigate(`/padlock?padLockId=${response.data.padLockId}`, {
        replace: true,
      });
    } catch (error) {
      console.log(error);
      showToast("생성 요청이 처리되지 않았습니다. 다시 시도해 주세요.", false);
    }
  };

  const categories = [
    { name: "기본", id: "basic" },
    { name: "하트", id: "heart" },
    { name: "편지", id: "letter" },
    { name: "선물", id: "gift" },
    { name: "복주머니", id: "bag" },
    { name: "눈사람", id: "snowman" },
  ];

  const places = [
    { id: 1, name: "남산 타워" },
    { id: 2, name: "경주 하서항" },
    { id: 3, name: "명동 성당" },
    { id: 4, name: "포항 호미곶" },
    { id: 5, name: "별마당 도서관" },
    { id: 6, name: "성산 일출봉" },
  ];
  const lockContainerRef = useRef({});

  const handleCardClick = (id) => {
    // console.log(id);
    setSelectedCardId(id);
  };

  const handleCategoryClick = (categoryId) => {
    // console.log(categoryId);
    setSelectedCategory(categoryId);
    setSelectedCardId(locks[categoryId][0].id);
  };

  const handlePlaceChange = (e) => {
    const index = e.target.selectedIndex;
    dispatch(setPlaceId(index + 1));
    dispatch(setBackgroundId(index + 1));
  };
  return (
    <>
      <header className="relative w-full max-w-[400px] flex items-center mt-[20px] mb-4">
        <IoIosArrowBack
          className="absolute left-[20px] text-3xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="w-full text-center font-bold text-2xl">자물쇠 만들기</h1>
      </header>

      <form
        className="flex flex-col justify-center items-center gap-5
        w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        {/* 명소 선택 */}
        <div className="flex flex-row items-center">
          <FaLocationDot className="w-[15px] h-[15px] mr-[4px]" />
          <select
            className="bg-black/0 text-xl font-bold text-center"
            onChange={handlePlaceChange}
          >
            {places.map((place) => {
              return (
                <option
                  className="bg-black font-bold text-lg"
                  value={place.name}
                >
                  {place.name}
                </option>
              );
            })}
          </select>
        </div>
        {/* 미리 보기 */}
        {selectedCardId ? (
          <img
            className="h-[150px]"
            src={require("assets/locks" +
              locks[selectedCategory][(selectedCardId - 1) % 6].src)}
            alt=""
          />
        ) : (
          <img
            className="h-[150px]"
            src={require("assets/locks" + locks[selectedCategory][0].src)}
            alt=""
          />
        )}
        {/* 자물쇠 목록 */}
        <section
          className="flex flex-col
                            w-[320px]
                            px-4 py-1 rounded-lg
                            bg-black/80 "
        >
          <ul className="flex overflow-x-auto whitespace-nowrap scrollbar-hide gap-2">
            {categories.map((category) => (
              <li
                key={category.id}
                className={`px-3 py-1 font-semibold rounded-md text-sm
                   ${
                     category.id === selectedCategory
                       ? "text-white bg-[#433DE7]"
                       : "bg-white/70 text-black"
                   }`}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.name}
              </li>
            ))}
          </ul>
          <section className="flex flex-col mt-4 overflow-y-auto h-[190px]">
            {Object.entries(locks).map(([categoryId, lockList]) => {
              if (categoryId == selectedCategory) {
                return (
                  <section
                    key={categoryId}
                    id={`${categoryId}`}
                    ref={(el) => (lockContainerRef.current[categoryId] = el)}
                    className="mb-4"
                  >
                    <ul className="grid grid-cols-3 gap-3">
                      {lockList.map((lock) => (
                        <LockCard
                          key={lock.id}
                          src={require("assets/locks" + lock.src)}
                          isSelected={selectedCardId === lock.id}
                          onClick={() => handleCardClick(lock.id)}
                        />
                      ))}
                    </ul>
                  </section>
                );
              }
            })}
          </section>
        </section>
        {/* 메시지 작성 */}
        <section className="relative">
          <textarea
            value={lockmessage}
            onChange={handleInputChange}
            className="text-white bg-black/80
          w-[320px] h-[120px] rounded-xl p-4
          resize-none"
            placeholder={`최대 ${maxLength}자 입력 가능합니다.`}
          />
          <p
            className={`absolute right-3 bottom-3 text-sm ${
              lockmessage.length === maxLength
                ? "text-red-500"
                : "text-gray-500"
            }`}
          >
            {lockmessage.length}/{maxLength}자
          </p>
        </section>

        {/* 완료 버튼 */}
        <button
          className={
            "bg-black/80 text-white font-bold py-2 h-[50px] px-20 rounded-xl"
          }
          onClick={handleButtonClick}
        >
          생성하기
        </button>
      </form>
    </>
  );
};

export default CreateLockPage;
