import { useNavigate } from "react-router-dom";
import image1 from "assets/attractions/attraction-1.png";
import image2 from "assets/attractions/attraction-2.png";
import image3 from "assets/attractions/attraction-3.png";
import image4 from "assets/attractions/attraction-4.png";
import image5 from "assets/attractions/attraction-5.png";
import image6 from "assets/attractions/attraction-6.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setBackgroundId } from "stores/backgroundSlice";
import { FaChevronDown } from "react-icons/fa";
import SelectHeader from "components/SelectHeader";
import PolaroidCard from "./PolaroidCard";

const SelectPlacePage = () => {
  const [selectedCardId, setSelectedCardId] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCardClick = (id, src, name) => {
    setSelectedCardId(id);
    dispatch(setBackgroundId(id));
  };

  const handleNavigate = () => {
    navigate(`/home?placeId=${selectedCardId}`);
  };

  return (
    <>
      <SelectHeader />
      {/* 전체 화면을 감싸는 클릭 가능 영역 */}
      <div
        className="fixed inset-0 z-0" // 전체 화면 덮는 컨테이너
        onClick={handleNavigate}
      >
        <section className="flex flex-col items-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg text-stone-400 gap-0.5">
          <p>화면을 누르면</p>
          <p>해당 장소로</p>
          <p>이동합니다!</p>
        </section>
      </div>

      {/* 폴라로이드 카드 영역 (이벤트 전파 막기) */}
      <div
        className="flex flex-col items-center bg-black/80 fixed bottom-0 py-1 z-10"
        style={{
          maxWidth: "440px",
          minWidth: "360px",
          margin: "0 auto",
        }}
        onClick={(e) => e.stopPropagation()} // 상위 클릭 이벤트 전파 막기
      >
        <FaChevronDown onClick={() => navigate(-1)} />
        <section className="grid grid-cols-6 gap-2 place-items-center my-2 mx-4">
          <PolaroidCard
            imgPath={image1}
            placeName="남산타워"
            onClick={() => handleCardClick(1, image1, "남산타워")}
          />
          <PolaroidCard
            imgPath={image2}
            placeName="경주하서항"
            onClick={() => handleCardClick(2, image2, "경주 하서항")}
          />
          <PolaroidCard
            imgPath={image3}
            placeName="명동성당"
            onClick={() => handleCardClick(3, image3, "명동 성당")}
          />
          <PolaroidCard
            imgPath={image4}
            placeName="포항호미곶"
            onClick={() => handleCardClick(4, image4, "포항 호미곶")}
          />
          <PolaroidCard
            imgPath={image5}
            placeName="별마당도서관"
            onClick={() => handleCardClick(5, image5, "별마당 도서관")}
          />
          <PolaroidCard
            imgPath={image6}
            placeName="성산일출봉"
            onClick={() => handleCardClick(6, image6, "성산 일출봉")}
          />
        </section>
      </div>
    </>
  );
};

export default SelectPlacePage;
