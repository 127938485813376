import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lockId: 0,
  message: "",
  placeId: 0,
  design: 0,
  imagePath: "/standard/lock1.png",
};

export const subLockSlice = createSlice({
  name: "subLock",
  initialState,
  reducers: {
    setLockId: (state, action) => {
      state.lockId = action.payload;
    },
    setPlaceId: (state, action) => {
      state.placeId = action.payload;
    },
    setdesign: (state, action) => {
      state.design = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setImagePath: (state, action) => {
      state.imagePath = action.payload;
    },
  },
});

export const { setLockId, setPlaceId, setdesign, setMessage, setImagePath } = subLockSlice.actions;
export default subLockSlice.reducer;
