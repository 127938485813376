import React, { createContext, useContext, useState, useCallback } from "react";
import { FaCheck } from "react-icons/fa";
import { FaCircleExclamation } from "react-icons/fa6";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);
  const [isSuccess, setIsSuccess] = useState(true)

  const showToast = useCallback((message, responsedStatus) => {
    setToast(message);
    setIsSuccess(responsedStatus)
    setTimeout(() => setToast(null), 3000); // 3초 후 자동 닫힘
  }, []);

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toast && <Toast message={toast} isSuccess={isSuccess}/>}
    </ToastContext.Provider>
  );
};

const Toast = ({ message, isSuccess }) => (
  <div className="fixed bottom-5 left-1/2 
                transform -translate-x-1/2
                bg-black text-white 
                flex flex-row justify-center items-center
                w-[360px] h-[70px] px-[50px] py-2 rounded-2xl shadow-lg 
                text-base text-center
                z-50">
    {isSuccess ? <FaCheck color={"#00FF00"} className="fixed left-6" /> : <FaCircleExclamation color={"#FF0000"} className="fixed left-6"/>}
    {message}
  </div>
);

export const useToast = () => useContext(ToastContext);
