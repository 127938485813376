import { useEffect, useRef, useState } from "react";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import lockImage from "assets/locks/lock-1.png";
import lockImage2 from "assets/locks/light/letter4.png";
import image1 from "assets/attractions/attraction-1.png";
import image2 from "assets/attractions/attraction-2.png";
import image3 from "assets/attractions/attraction-3.png";
import image4 from "assets/attractions/attraction-4.png";
import image5 from "assets/attractions/attraction-5.png";
import image6 from "assets/attractions/attraction-6.png";
import { useNavigate } from "react-router-dom";

const IntroductionPage = () => {
  const navigate = useNavigate();
  const isScrolling = useRef(false);
  const [isEntry, setIsEntry] = useState(true);
  const [step, setStep] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [guideMessage, setGuideMessage] = useState("로딩 중...");
  const [arrowType, setArrowType] = useState("up");
  const [style, setStyle] = useState({
    opacity: 0,
    transform: "translateY(10px)",
  });
  const [style2, setStyle2] = useState({
    marginTop: "15px",
    transform: "scale(0.75)",
    transition: "transform 1s ease-in-out",
  });
  const [style3, setStyle3] = useState({
    marginTop: "15px",
    opacity: 0,
    transform: "translateY(10px)",
  });

  const places = [
    { id: 1, src: image1, name: "남산 타워" },
    { id: 2, src: image2, name: "경주 하서항" },
    { id: 3, src: image3, name: "명동 성당" },
    { id: 4, src: image4, name: "포항 호미곶" },
    { id: 5, src: image5, name: "별마당 도서관" },
    { id: 6, src: image6, name: "성산 일출봉" },
  ];

  const detectDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("mobile")) {
      return "mobile";
    } else if (userAgent.includes("tablet") || userAgent.includes("ipad")) {
      return "tablet";
    } else {
      return "desktop";
    }
  };

  useEffect(() => {
    console.log("intro");
    const deviceType = detectDevice();

    if (deviceType === "mobile") {
      setGuideMessage("위로 스와이프 해주세요.");
    } else if (deviceType === "tablet") {
      setGuideMessage("위로 스와이프 해주세요.");
    } else {
      setGuideMessage("아래로 스크롤 해주세요.");
      setArrowType("down");
    }

    if (step < 4) {
      setStyle({
        opacity: 0,
        transform: "translateY(10px)",
      });
    }
    if (step === 0) {
      setStyle2({
        marginTop: "15px",
        transform: "scale(0.75)",
        transition: "transform 1s ease-in-out",
      });
    } else if (step == 1) {
      const timer = setTimeout(() => {
        setStyle2({
          marginTop: "15px",
          transform: "scale(1.2)",
          transition: "transform 1s ease-in-out",
        });
      }, 100); // 애니메이션 시작 시간
      return () => clearTimeout(timer);
    } else if (step === 2) {
      setIsVisible(true);
    } else if (step >= 4) {
      const timer = setTimeout(() => {
        setStyle({
          opacity: 1,
          transform: "translateY(0)",
          transition: "opacity 1.5s, transform 1.5s",
        });
      }, 300); // 초기 딜레이
      if (step === 5) {
        console.log("dd");
        const timer2 = setTimeout(() => {
          setStyle3({
            opacity: 1,
            transform: "translateY(0)",
            transition: "opacity 1.5s, transform 1.5s",
          });
        }, 300); // 초기 딜레이
        return () => clearTimeout(timer2);
      }

      return () => clearTimeout(timer);
    }
  }, [step]);

  const handleButtonClick = () => {
    localStorage.setItem("hasVisited", "true");
    navigate("/login");
  };

  const handleScroll = (e) => {
    if (isScrolling.current) return;

    console.log("스크롤");
    if (isEntry) {
      setIsEntry(false);
      return;
    }
    if (step === 3) {
      const scrollContainer = document.querySelector(".scroll-container");
      if (scrollContainer) {
        // deltaY로 수평 스크롤 대체 (마우스 세로 스크롤로 가로 스크롤)
        scrollContainer.scrollLeft += e.deltaY; // deltaY값으로 수평 이동
      }
      // if (scrollContainer.scrollLeft <= 0) {
      //   setStep(step - 1);
      // }
      // if (
      //   scrollContainer.scrollLeft + scrollContainer.clientWidth >=
      //   scrollContainer.scrollWidth
      // ) {
      //   setStep(step + 1);
      // }
    }

    if (step != 3) {
      if (e.deltaY > 0) {
        console.log("아래로 스크롤");
        if (step < 5) {
          setStep(step + 1);
        }
      } else {
        console.log("위로 스크롤");
        // if (step > 0) {
        //   setStep(step - 1);
        // }
      }
    }

    isScrolling.current = true;

    // 일정 시간 후 스크롤 허용
    if (step === 3) {
      isScrolling.current = false;
    } else {
      setTimeout(() => {
        isScrolling.current = false;
      }, 1000); // 1초 동안 차단
    }
  };

  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);

  const handleTouchStart = (event) => {
    setTouchStartY(event.touches[0].clientY);
  };

  const handleTouchEnd = (event) => {
    setTouchEndY(event.changedTouches[0].clientY);
    if (isEntry) {
      setIsEntry(false);
      return;
    }

    if (touchStartY < event.changedTouches[0].clientY) {
      console.log("아래로 스와이프");
      // if (step != 3) {
      //   setStep(step - 1);
      // }
    } else if (touchStartY > event.changedTouches[0].clientY) {
      console.log("위로 스와이프");
      if (step != 3 && step < 5) {
        setStep(step + 1);
      }
    }
  };

  const scrollContainerRef = useRef(null);

  const [touchStartX, setTouchStartX] = useState(0);

  const handleTouchXStart = (event) => {
    // 터치 시작 위치 저장
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    const touchEndX = event.touches[0].clientX;

    // 터치 이동 거리를 계산
    const deltaX = touchStartX - touchEndX;

    // 스크롤 컨테이너 참조
    const scrollContainer = document.querySelector(".scroll-container");
    if (scrollContainer) {
      scrollContainer.scrollLeft += deltaX; // 가로 스크롤 이동
    }

    // 터치 시작 위치를 현재 위치로 업데이트 (연속적인 스크롤)
    setTouchStartX(touchEndX);
    // const scrollContainer = document.querySelector(".scroll-container");
    //   if (scrollContainer) {
    //     // deltaY로 수평 스크롤 대체 (마우스 세로 스크롤로 가로 스크롤)
    //     scrollContainer.scrollLeft += e.deltaY; // deltaY값으로 수평 이동
    //   }
  };

  return (
    <>
      <main
        className="flex flex-col justify-center h-[100dvh] touch-none text-white min-w-[360px] max-w-[440px] mx-auto overflow-hidden relative"
        style={{
          background: `linear-gradient(
            180deg,
            rgba(22, 5, 57, 0.9) 0%,
            rgba(59, 13, 71, 0.47) 27%,
            rgba(0, 38, 83, 0.6) 65%,
            rgba(23, 67, 110, 0.82) 100%
          )`,
        }}
        onWheel={handleScroll}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {/* 스크롤{step} */}
        {step < 3 && (
          <section className="h-[90dvh] overflow-hidden">
            <img
              // className={scale === 1 ? "scale-100" : "scale-50"}
              src={lockImage}
              style={style2}
              alt=""
            />
            <p className="text-center text-xl font-bold">어서오세요</p>
            <div
              className={`mt-[20px] transition-all duration-1000 transform ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              {step === 2 && (
                <div className="text-center space-y-2">
                  <p className="text-xl">Lock Our Memory 는</p>
                  <p className="text-xl">가족, 친구, 지인들과</p>
                  <p className="text-xl">추억을 남기는 서비스 입니다.</p>
                </div>
              )}
            </div>
          </section>
        )}
        {step === 3 && (
          <section
            className="flex flex-col px-5 h-[90vh] overflow-hidden scroll-container justify-center"
            onTouchStart={handleTouchXStart}
            onTouchMove={handleTouchMove}
          >
            <div
              className="flex flex-row w-max h-[300px] gap-3"
              ref={scrollContainerRef}
              style={{ scrollBehavior: "smooth" }}
            >
              {places.map((place, index) => {
                return (
                  <div
                    key={index}
                    className={`
                flex flex-col
                justify-center items-center 
                w-[300px] h-[300px]
                bg-white
              `}
                  >
                    <img
                      className={`w-[270px] h-[230px]
                bg-slate-300
                aspect-square
                
                `}
                      src={place.src}
                      alt="Attraction"
                    />
                    <p className="text-black font-bold text-sm pt-1">
                      {place.name}
                    </p>
                  </div>
                );
              })}
              <div className="flex flex-col justify-center">
                <p
                  className="place-content-center text-center text-xl font-bold
                              pl-10 pr-10"
                >
                  여러 명소를 둘러보며
                  <br />
                  나에게 맞는 장소에
                  <br />
                  추억 자물쇠를 걸 수 있어요!
                </p>
                <button className="mt-[15px]" onClick={() => setStep(step + 1)}>
                  다음
                </button>
              </div>
            </div>
          </section>
        )}
        {step >= 4 && (
          <section className="flex flex-col h-[90vh] justify-center items-center">
            <img className="w-[300px]" src={lockImage2} alt="" />
            <p className="text-center text-xl font-bold" style={style}>
              가족, 친구, 지인들이 남긴
              <br />
              자물쇠에 메세지를 남겨서
              <br />
              우리의 추억을 완성해보아요!
            </p>
            {step === 5 && (
              <button
                className="w-[250px] mt-[30px] py-4 px-[20px] rounded-lg bg-slate-200
                 text-black font-bold"
                onClick={handleButtonClick}
                style={style3}
              >
                시작하기
              </button>
            )}
          </section>
        )}
        {isEntry && (
          <div className="flex justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
            <div className="flex items-center justify-center fixed top-[45dvh] z-50">
              {arrowType === "up" ? (
                <MdKeyboardDoubleArrowUp className="w-[40px] h-[40px]" />
              ) : (
                <MdKeyboardDoubleArrowDown className="w-[40px] h-[40px]" />
              )}

              <span className="text-[20px] pr-[15px]">{guideMessage}</span>
            </div>
          </div>
        )}
        <button
          className="absolute right-5 top-5 text-sm text-slate-300"
          onClick={handleButtonClick}
        >
          건너뛰기
        </button>
      </main>
    </>
  );
};

export default IntroductionPage;
