const MessageModal = ({ onClose, data }) => {
    console.log(data)
  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <div
      className="fixed inset-0 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-black/90 rounded-lg p-5 w-80 flex flex-row shadow-lg gap-2 items-center px-4"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={require(`assets/locks/light/${data.messageImage}`)} alt=""
          className="w-20 h-20"
        />
        <h2 className="w-full text-center">{data.content || "메시지내용"}</h2>
      </div>
    </div>
  );
};

export default MessageModal;
