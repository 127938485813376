import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";

export default function DetailHeader({ onClick, ownerName, placeId }) {
  const navigate = useNavigate();
  const nickname = useSelector((state) => state.user.nickname);

  return (
    <header className="flex justify-around gap-4 items-center mt-10 w-full">
      <IoIosArrowBack
        className="text-3xl cursor-pointer"
        onClick={() => navigate(`/home?placeId=${placeId}`)}
      />
      <h2 className="font-bold text-xl">{ownerName}의 자물쇠</h2>
      <span></span>
      {nickname && (
        <FaBars
          style={{ fontSize: "20px", color: "white" }}
          className="absolute right-10"
          onClick={() => onClick()}
        />
      )}
    </header>
  );
}
