import { FaChevronLeft, FaEllipsisV, FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteMessage, getLockDetail } from "apis/lockApi";
import messageBackground1 from "assets/message_bg_type1.png";
import messageBackground2 from "assets/message_bg_type2.png";
import { IoIosArrowBack } from "react-icons/io";
import { useToast } from "components/ToastContext";

function DeleteModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/20">
      <div className="bg-black rounded-xl p-6 w-11/12 max-w-sm text-center">
        <button
          className="absolute top-2 right-2 text-white text-lg"
          onClick={onClose}
        >
          ✕
        </button>
        <p className="text-white my-6 text-lg font-bold">
          정말 삭제하시겠습니까?
        </p>
        <div className="flex justify-around">
          <button
            className="bg-red-500 text-white px-8 py-2 rounded-xl"
            onClick={onConfirm}
          >
            삭제
          </button>
          <button
            className="bg-white text-black px-8 py-2 rounded-xl"
            onClick={onClose}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
}

export default function MessageListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const showToast = useToast();
  const [messages, setMessages] = useState([]);
  const [padLockId, setPadLockId] = useState(null);
  // const { messages = [] } = location.state || {}; // 기본값 설정
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  console.log(messages);
  const handleDeleteClick = (messageId) => {
    setSelectedMessageId(messageId);
    setIsModalOpen(true); // 모달 열기
  };

  useEffect(() => {
    setMessages(location.state.messages);
    setPadLockId(location.state.padLockId);
  }, []);

  const handleConfirmDelete = async () => {
    try {
      await deleteMessage(selectedMessageId); // 삭제 API 호출
      setIsModalOpen(false); // 모달 닫기
      // 삭제 후 메시지 리스트 갱신 로직 필요 (API 재호출 또는 상태 업데이트)
      showToast("메시지가 삭제되었습니다.", true);
      getMessage()
    } catch (error) {
      showToast(
        "삭제 요청이 처리되지 않았습니다. 잠시 후 다시 시도해 주세요.",
        false
      );
    }
  };

  const getMessage = async () => {
    try {
      const response = await getLockDetail(padLockId);
      setMessages(response.data.messages);
      // console.log(response)
    } catch (error) {
      console.error("Error fetching lock detail:", error);
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
      <header className="relative w-full max-w-[400px] flex items-center mt-[20px] mb-[30px]">
        <IoIosArrowBack
          className="absolute left-[20px] text-3xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="w-full text-center font-bold text-xl">받은 메시지</h1>
      </header>

      <div className="w-full flex flex-col items-center gap-4">
        {messages.map((message, index) => (
          <div
            className={`relative flex items-center w-[340px] h-[115px] ml-[10px]
              ${index % 2 === 0 ? "text-black" : "text-white"}`}
            key={message.messageId}
            style={{
              backgroundImage:
                index % 2 === 0
                  ? `url(${messageBackground1})`
                  : `url(${messageBackground2})`,
            }}
          >
            <img
              src={require(`assets/locks/light/${message.messageImage}`)}
              alt="메시지 이미지"
              className="w-16 h-16"
            />
            <div className="w-[220px] pl-[10px]">
              <p className="text-md font-bold">
                {message.content || " 메시지 내용 없음"}
              </p>
              <div className="flex flex-row items-center justify-between mt-10">
                {/* 공개/비공개 아이콘 추가 */}
                <div className="flex items-center gap-2">
                  {message.visible ? (
                    <FaEye className="text-green-500" />
                  ) : (
                    <FaEyeSlash className="text-red-500" />
                  )}
                  <p className="text-[12px]">
                    {message.visible ? "공개" : "비공개"}
                  </p>
                </div>
                <p className="text-[12px] font-bold">
                  from. {message.ownerName}
                </p>
              </div>
            </div>
            <FaEllipsisV
              className="cursor-pointer absolute top-3 right-9 w-[5px]"
              onClick={() => handleDeleteClick(message.messageId)}
            />
          </div>
        ))}
      </div>
    </>
  );
}
