import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ShareModal from "./ShareModal";
import image1 from "assets/attractions/attraction-1.png";
import image2 from "assets/attractions/attraction-2.png";
import image3 from "assets/attractions/attraction-3.png";
import image4 from "assets/attractions/attraction-4.png";
import image5 from "assets/attractions/attraction-5.png";
import image6 from "assets/attractions/attraction-6.png";
import lockImage1 from "assets/locks/lock-1.png";
import lockImage2 from "assets/locks/lock-2.png";
import lockImage3 from "assets/locks/lock-3.png";
import lockImage4 from "assets/locks/lock-4.png";
import { BsDownload } from "react-icons/bs";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { FaBars } from "react-icons/fa";
import { GoLink } from "react-icons/go";
import SideBar from "components/SideBar";

import InstagramIcon from "./InstagramIcon";
import naverbandIcon from "assets/share_buttons/naverband.png";
import KakaoShareButton from "./KakaoTalkShare";
import XIcon from "assets/share_buttons/X-logo-white.png";
import FacebookShare from "./FacebookShare";

const SharePage = () => {
  const userName = useSelector((state) => state.user.nickname);
  const userMessage = useSelector((state) => state.mainLock.message);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const placeId = useSelector((state) => state.mainLock.placeId);
  const design = useSelector((state) => state.mainLock.design);
  const location = useLocation();
  const { padLockData } = location.state || {};
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  console.log(padLockData);
  const iconSize = "w-[30px] h-[30px]";
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCopyClick = () => {
    // Clipboard API 지원 여부 체크
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText("https://mydevlogme.site/share?code=" + padLockData.shareUrl)
        .then(() => {
          alert("링크가 clipboard api로 복사되었습니다!");
        })
        .catch((err) => {
          alert("클립보드 복사 실패");
          console.error(err);
        });
    } else {
      // Clipboard API가 없을 경우 대체 방법 사용
      const textArea = document.createElement("textarea");
      textArea.value = shareLink;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("링크가 복사되었습니다!");
    }
  };

  const canvasRef = useRef(null);

  const getImageSrc = (status) => {
    switch (status) {
      case 1:
        return image1;
      case 2:
        return image2;
      case 3:
        return image3;
      case 4:
        return image4;
      case 5:
        return image5;
      case 6:
        return image6;
      default:
        return image1;
    }
  };

  const getLockImageSrc = (status) => {
    switch (status) {
      case 1:
        return lockImage1;
      case 2:
        return lockImage2;
      case 3:
        return lockImage3;
      case 4:
        return lockImage4;
      default:
        return lockImage1;
    }
  };

  const truncateText = (ctx, text) => {
    let truncated = text;
    while (
      ctx.measureText(truncated + "...").width > 512 &&
      truncated.length > 0
    ) {
      truncated = truncated.slice(0, -1); // 텍스트 길이를 하나씩 줄임
    }
    return truncated.length < text.length ? truncated + "..." : text;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = 1024;
    canvas.height = 1024;

    const image = new Image();
    image.src = getImageSrc(placeId);

    const lockImage = new Image();
    lockImage.src = getLockImageSrc(design); // 추가 이미지 경로

    image.onload = () => {
      // 배경색상
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 이미지 렌더링
      ctx.drawImage(image, 45, 45, canvas.width * 0.91, canvas.width * 0.66);

      // 텍스트 추가
      ctx.font = "40px Arial";
      ctx.fillStyle = "#868686";
      ctx.fillText("# Lock Our Memory", 50, 790);
      ctx.fillText("# 마음을 전해요", 50, 850);

      // 동적 텍스트 위치 계산
      const userText = `From. ${userName}`;
      // 메시지와 사용자 텍스트를 축약
      const truncatedMessage = truncateText(ctx, padLockData.content);
      const truncatedUserText = truncateText(ctx, padLockData.ownerName);
      const messageWidth = ctx.measureText(truncatedMessage).width; // 텍스트 폭 측정
      const userTextWidth = ctx.measureText(truncatedUserText).width;

      ctx.fillStyle = "black";
      ctx.fillText(truncatedMessage, canvas.width - messageWidth - 50, 790); // 오른쪽 여백 50px
      ctx.fillText(truncatedUserText, canvas.width - userTextWidth - 50, 890);

      // 날짜 정렬
      const dateString = padLockData.createDate;
      const date = new Date(dateString);
      const dateText =
        date.getFullYear() + "." + (date.getMonth() + 1) + "." + date.getDate();
      const dateWidth = ctx.measureText(dateText).width;
      ctx.fillText(dateText, canvas.width - dateWidth - 50, 960);

      // 이미지 추가
      lockImage.onload = () => {
        const imageSize = 500; // 추가 이미지 크기
        const imageX = canvas.width / 2 - 250; // 사용자 텍스트 왼쪽 위치
        const imageY = 130; // 텍스트와 이미지의 세로 위치 정렬

        ctx.drawImage(lockImage, imageX, imageY, imageSize, imageSize);
      };

      // 중앙 URL
      ctx.fillStyle = "#ED8F8F";
      ctx.fillText("lockourmemory.site", 50, 960);
    };
  }, []);

  const handleSaveImage = () => {
    const canvas = canvasRef.current;
    const dataUrl = canvas.toDataURL("image/png");

    // 다운로드 링크 생성 및 트리거
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "edited-image.png";
    link.click();
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="relative w-full max-w-[400px] flex items-center mt-[20px] mb-4">
        <IoIosArrowBack
          className="absolute left-[20px] text-3xl cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <h1 className="w-full text-center font-bold text-[18px]">공유하기</h1>
        <FaBars
          style={{ fontSize: "20px", color: "white" }}
          className="absolute right-10"
          onClick={handleOpenMenu}
        />
      </header>

      <main
        className="flex flex-col items-center w-[320px] h-[430px] bg-black/60 
      p-[20px] rounded-md
      aspect-square my-6 relative"
      >
        <canvas
          ref={canvasRef}
          style={{ width: "100%", height: "auto" }} // 화면 폭에 맞게 조정
        />
        {/* <section
          id="share_buttons"
          className="flex flex-row w-full justify-around pt-[15px]"
        >
          <FacebookShare iconSize={iconSize} />
          
          <img src={naverbandIcon} alt="naverband_logo" className={iconSize} />
          <img
            src={XIcon}
            alt="X_logo"
            className={`bg-black p-[8px] rounded-md ${iconSize}`}
          />
          <KakaoShareButton iconSize={iconSize} />
          <InstagramIcon />
        </section> */}
        <section
          id="share_link"
          className="flex items-center justify-center gap-2 mt-5"
        >
          <span className="text-sm text-gray-800 bg-gray-200 px-4 py-1 rounded-md w-[228px] text-center overflow-x-scroll scrollbar-hide whitespace-nowrap">
            {"https://mydevlogme.site/share?code=" + padLockData.shareUrl}
          </span>
          <button
            onClick={handleCopyClick}
            className="bg-blue-800 text-white px-3 py-2 rounded-md text-xs font-bold hover:bg-blue-600"
          >
            <GoLink />
          </button>
        </section>
        <button
          onClick={handleSaveImage}
          className="flex flex-row justify-between items-center w-[140px] px-[15px] py-[5px] mt-[20px] bg-slate-800 text-white rounded"
        >
          이미지 저장
          <BsDownload />
        </button>
        {/* <div
          class="fb-share-button"
          data-href="https://developers.facebook.com/docs/plugins/"
          data-layout=""
          data-size="button_count"
          >
          <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
          class="fb-xfbml-parse-ignore"
          >
          공유하기
          </a>
          </div> */}
      </main>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className="flex flex-row px-[40px] py-[10px] bg-black/70 text-white rounded-xl"
      >
        자물쇠 돌아가기
      </button>

      {/* {isModalOpen && (
        <ShareModal
          onClose={closeModal}
          links={{
            kakao: "https://kakao.com",
            band: "https://band.us",
            instagram: "https://instagram.com",
            // site: "https://example.com",
          }}
          copyLink="https://www.extra-long-link-example.com"
          mainLockId={padLockData.mainLockId}
        />
      )} */}

      <SideBar isOpen={isMenuOpen} onClose={handleCloseMenu} />
    </>
  );
};

export default SharePage;
