import React, { useState } from "react";
import select from "assets/tab-icons/select-place.png";
import create from "assets/tab-icons/create-lock.png";
import mypage from "assets/tab-icons/my-lock.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoginModal } from "./BottomTab";

export default function BottomTabInDetailPage({ data }) {
  const navigate = useNavigate();
  const nickname = useSelector((state) => state.user.nickname);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRestrictedAction = (action) => {
    if (!nickname) {
      setIsModalOpen(true); // 모달 표시
    } else {
      action();
    }
  };

  return (
    <>
      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <nav
        className="absolute bottom-0 left-auto flex flex-row justify-between items-center py-4 px-5 bg-black/80"
        style={{
          width: "100vw",
          maxWidth: "440px",
          minWidth: "360px",
        }}
      >
        <div
          className="flex flex-col items-center gap-2 justify-center"
          onClick={() => navigate("/select-place")}
        >
          <img src={select} alt="명소 선택하기" className="w-12" />
          <p className="text-xs text-yellow-400">명소 둘러보기</p>
        </div>
        <div
          className="flex flex-col items-center gap-2 justify-center"
          onClick={() =>
            handleRestrictedAction(() =>
              navigate("/create-message", { state: { data } })
            )
          }
        >
          <img src={create} alt="자물쇠 생성" className="w-12" />
          <p className="text-xs text-yellow-400">메시지 남기기</p>
        </div>
        <div
          className="flex flex-col items-center gap-2 justify-center"
          onClick={() => handleRestrictedAction(() => navigate("/mypage"))}
        >
          <img src={mypage} alt="내 자물쇠 보기" className="w-12" />
          <p className="text-xs text-yellow-400">내 자물쇠 보기</p>
        </div>
      </nav>
    </>
  );
}
