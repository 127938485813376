import { useNavigate } from "react-router-dom";
import { RxExclamationTriangle } from "react-icons/rx";

const ErrorPage = () => { 
    const navigate = useNavigate()
    return (
        <main className="fixed inset-0 flex flex-col justify-center items-center bg-[#616161]">
          <h1 className="text-[45px] font-semibold mb-[20px]">404 ERROR</h1>
          <RxExclamationTriangle className="w-[260px] h-[260px]" />
          <p className="text-[17px] font-semibold mb-[10px]">페이지를 찾을 수 없습니다.</p>
          <p className="text-[14px] font-semibold">페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.</p>
          <p className="text-[14px] font-semibold">입력하신 주소가 정확한지 다시 한 번 확인해주세요.</p>
          <button className="mt-[20px] w-[300px] h-[50px] bg-[#f4f4f4] text-black text-[17px] font-semibold rounded-lg"
          onClick={() => navigate('/')}>메인으로 돌아가기</button>
        </main>
      );
}

export default ErrorPage