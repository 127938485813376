import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LockDetailSlider from "./LockDetailSlider";
import { useDispatch, useSelector } from "react-redux";
import { deleteLock, getLockDetail, updateLock } from "apis/lockApi";
import DetailHeader from "components/DetailHeader";
import SideBar from "components/SideBar";
import BottomTabInDetailPage from "components/BottomTabInDetailPage";
import { setBackgroundId } from "stores/backgroundSlice";

function EditModal({ isOpen, content, onClose, onSave }) {
  const [inputValue, setInputValue] = useState(content);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 200) {
      setInputValue(value);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
      <div className="bg-black rounded-xl p-6 w-11/12 max-w-sm relative">
        <textarea
          className="w-full h-32 p-4 bg-transparent text-white border border-gray-500 rounded-lg resize-none"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="내용을 입력하세요."
        />
        <p className="text-right text-white text-sm mt-2">
          {inputValue.length}/200자
        </p>
        <div className="flex justify-around mt-6">
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded-lg"
            onClick={() => onSave(inputValue)}
          >
            수정
          </button>
          <button
            className="bg-gray-700 text-white px-6 py-2 rounded-lg"
            onClick={onClose}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
}

export default function PadLockDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const padLockId = searchParams.get("padLockId");
  const userId = useSelector((state) => state.user.userId);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // 수정 모달 상태
  const [data, setData] = useState(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLockDetail(padLockId);
        setData(response.data);
        setContent(response.data.content);
        console.log(response.data);
        dispatch(setBackgroundId(response.data.placeId));
      } catch (error) {
        console.error("Error fetching lock detail:", error);
      }
    };

    if (padLockId) {
      fetchData();
    }
  }, [padLockId]);

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteLock(padLockId);
      console.log("삭제가 완료되었습니다.");
      setIsDeleteModalOpen(false);
      navigate("/home", { state: { refresh: true } });
    } catch (error) {
      console.error("삭제 실패:", error);
      alert("삭제에 실패했습니다. 다시 시도해주세요.");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSaveEdit = (newContent) => {
    setContent(newContent); // 수정된 내용 저장
    setIsEditModalOpen(false);
    console.log("수정된 내용:", newContent);
    // 필요 시 API 호출 추가
    updateLock({
      padLockId: padLockId,
      content: newContent,
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DetailHeader
        onClick={handleOpenMenu}
        ownerName={data.ownerName}
        placeId={data.placeId}
      />
      <article className="relative flex flex-col items-center justify-center w-fit my-4">
        {userId === data.ownerId ? (
          <>
            <div className="relative">
              <div className="w-fit h-fit p-4 border-2 border-yellow-500">
                <div className="absolute top-[0px] left-[50%] transform -translate-x-1/2 w-[2px] h-[16px] bg-yellow-500"></div>
                <div className="absolute bottom-[0px] left-[50%] transform -translate-x-1/2 w-[2px] h-[16px] bg-yellow-500"></div>
                <div className="absolute top-[50%] left-[0px] transform -translate-y-1/2 w-[16px] h-[2px] bg-yellow-500"></div>
                <div className="absolute top-[50%] right-[0px] transform -translate-y-1/2 w-[16px] h-[2px] bg-yellow-500"></div>
                <img
                  src={require(`assets/locks/light/${data.padLockImage}`)}
                  alt="메인 자물쇠"
                  className="relative"
                  style={{
                    height: "160px",
                    objectFit: "contain",
                    marginTop: "6px",
                  }}
                />
              </div>
              <div className="absolute top-6 right-[-85px] flex items-center">
                <div className="relative h-[160px] w-[2px] bg-yellow-500">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div
                      key={`long-${index}`}
                      className={`absolute ${
                        index === 3 ? "bg-red-500" : "bg-yellow-500"
                      }`}
                      style={{
                        top: `${index * 33.33}%`,
                        width: "12px",
                        height: "1px",
                      }}
                    ></div>
                  ))}
                  {Array.from({ length: 30 }).map((_, index) => (
                    <div
                      key={`short-${index}`}
                      className={`absolute ${
                        index >= 21 ? "bg-red-500" : "bg-yellow-300"
                      }`}
                      style={{
                        top: `${index * 3.33}%`,
                        width: "6px",
                        height: "1px",
                      }}
                    ></div>
                  ))}
                </div>
                <div className="flex flex-col text-left ml-4 font-bold">
                  <Link
                    to="/share"
                    state={{ padLockData: data }}
                    className="text-yellow-500 text-xs mb-[36px]"
                  >
                    공유
                  </Link>
                  <p
                    className="text-yellow-500 text-xs mb-[36px]"
                    onClick={() =>
                      navigate("/message", {
                        state: {
                          messages: data.messages,
                          padLockId: padLockId || [],
                        },
                      })
                    }
                  >
                    모아보기
                  </p>
                  <p
                    className="text-yellow-500 text-xs mb-[36px] cursor-pointer"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    수정
                  </p>
                  <p
                    className="text-red-500 text-xs cursor-pointer"
                    onClick={handleDeleteClick}
                  >
                    삭제
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <img
            src={require(`assets/locks/light/${data.padLockImage}`)}
            alt="메인 자물쇠"
            className="relative"
            style={{
              height: "160px",
              objectFit: "contain",
              marginTop: "6px",
            }}
          />
        )}
        <p className="text-center text-md font-bold mx-10 my-4">{content}</p>
        <LockDetailSlider
          isOwner={userId === data.ownerId}
          messages={data.messages || []}
        />
      </article>
      <BottomTabInDetailPage data={data} />
      <SideBar isOpen={isMenuOpen} onClose={handleCloseMenu} />
      <EditModal
        isOpen={isEditModalOpen}
        content={content}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleSaveEdit}
      />
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20">
          <div className="bg-black rounded-xl p-6 text-center text-white w-[300px]">
            <h2 className="text-lg font-bold my-10">정말 삭제하시겠습니까?</h2>
            <div className="flex justify-around">
              <button
                className="bg-red-500 text-white px-8 py-2 rounded-xl"
                onClick={handleConfirmDelete}
              >
                삭제
              </button>
              <button
                className="bg-white text-black px-8 py-2 rounded-xl"
                onClick={handleCancelDelete}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
